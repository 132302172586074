import classNames from "classnames";
import { ButtonLink } from "components/ButtonLink";
import Favorites from "components/header/Favorites";
import AfreecaSearchBar from "components/header/search/AfreecaSearchBar";
import useWindowScrollY from "components/hook/useWindowScrollY";
import ModalWindow from "components/ModalWindow";
import ReactPopup from "components/popup/ReactPopup";
import { DOMAIN_URL, BEST_BJ_DOMAIN, JAVASCRIPT_VOID, PARTNER_BJ_DOMAIN, AQUA_DOMAIN, SUPPORTERS_DOMAIN, STBBS_DOMAIN,APP_SCHEME } from "constant/config";
import { STYPE_HASHTAG, STYPE_MY_BOARD } from "constant/log";
import { NICKNAME_USER_ID, USER_ID } from "constant/search";
import { documentScrollTop, getLoginUrl, getLogoutUrl, isWebview, localStorageFactory, getAndroidCompareVersion, inAppStore, isAndroidWebview } from "helpers";
import { fetchLiveBlind } from "actions/blind.action";
import { fetchHidden } from "actions/hiddenbj.action";
import { searchLog } from "libs/searchLogCollection";
import React, { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import LoginArea from "components/header/LoginArea";
import Adballoon from "./Adballoon";
import BroadCastArea from "components/header/BroadCastArea";

export default function Header({ disableBackButton }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const [scrollTop] = useWindowScrollY();
    const { is_login, user_id, is_best_bj, is_partner_bj, is_af_supporters_bj } = useSelector((state) => state.authenticationReducer);
    const { stationInfo, bjInfo = {} } = useSelector((state) => state.stationReducer);
    const [color, setColor] = useState("#71b3d9");
    const [toolTipShow, setToolTipShow] = useState(false);

    const { loading, data = {} } = stationInfo;
    const { channelart = {} } = bjInfo;
    const { station = {}, is_owner } = data;
    const { station_name = "", station_no, user_id: station_id, user_nick: station_nick } = station;
    const { keyword = "" } = useParams();
    const [showTopBanner, setshowTopBanner] = useState(false); //노출여부
    const [fixedTopBanner, setFixedTopBanner] = useState(true); //상단고정여부
    const [headerStyle, setHeaderStyle] = useState({});
    const lang = document.documentElement.lang;

    /**
     * GNB를 고정할지 여부
     */
    useEffect(() => {
        setFixedTopBanner(showTopBanner === false || scrollTop >= 50);
    }, [scrollTop, showTopBanner]);

    /**
     * GNB postion 스타일변경
     */
    useEffect(() => {
        setHeaderStyle(fixedTopBanner ? { position: "fixed", top: 0 } : { position: "absolute", top: 50 });
    }, [fixedTopBanner]);

    useEffect(() => {
        var m = document.createElement("meta");
        m.name = "theme-color";
        m.content = color;
        document.head.appendChild(m);
    }, [color]);

    /**
     * 채널아트 색상이 변경되면
     */
    useEffect(() => {
        if (channelart.color) {
            setColor(channelart.color);
        }
    }, [channelart]);
    
    /**
     * 캐치 툴팁 추가확인
     */
    useEffect(() => {
        const catchToolTip = localStorageFactory.getItem('catch_tooltip_state') || 0;
        if (catchToolTip !== 'off') {
            setToolTipShow(true);
        }
    }, [channelart]);
    
    const handleBtnToolTipCloseClick = useCallback(()=>{
        setToolTipShow(false);
        localStorageFactory.setItem('catch_tooltip_state','off');
    });

    const handleGoBack = useCallback(() => {
        if (isWebview && history.length <= 1) {
            window.close();
        } else if (history.length <= 1) {
            history.push(`/${global.bj_id}`);
        } else {
            history.goBack();
        }
    }, [history]);

    const handleTitleClick = useCallback(() => {
        history.push(`/${global.bj_id}`);
        documentScrollTop();
    }, [history]);

    const handleSearchMyBoard = useCallback(
        (userId) => (event) => {
            searchLog({ keyword: userId, filter: USER_ID, searchType: STYPE_MY_BOARD });
        },
        [],
    );

    const handleTopBannerClose = useCallback((show) => {
        setshowTopBanner(show);
    }, []);

    const handleTargetBlind = (title_no, blind_id, modalClose) => {
		if (!is_login) { 
            window.location = getLoginUrl();
        } else {
            // 신고하기 기능과 신고 후 삭제/차단 기능 분리
            // 이 채널 숨기기
            if(window.confirm(t("이 채널을 숨기겠습니까?"))) {
                (async (title_no, blind_id, modalClose) => {
                    // LIVE 정보 확인 및 AOS func call
                    setTimeout(async () => {
                        await fetchLiveBlind(blind_id, true);
                    }, 300);
                    
                })(title_no, blind_id, modalClose);
                
            }
        }		
	}

    const handleTargetHidden = (title_no, hidden_id, hidden_nick, modalClose) => {
		if (!is_login) { 
            window.location = getLoginUrl();
        } else {
            // BJ 숨기기
            if(window.confirm(t("이 스트리머를 숨기겠습니까?"))) {
                (async (title_no, hidden_id, hidden_nick, modalClose) => {
                    // BJ 숨기기 AOS func call
                    setTimeout(async () => {
                        await fetchHidden(hidden_id, hidden_nick, true);
                    }, 300);
                    
                })(title_no, hidden_id, hidden_nick, modalClose);
                
            }
        }		
	}

    const handleMoveAquaPage = (event) => {
        event.preventDefault();

        window.open(
            AQUA_DOMAIN
        );
        return;
    }

    if (isMobile) {
        return (
            <header id="af-header" className={classNames({ "loading-header": loading })} style={{ position: "fixed" }}>
                <section className="title-section" style={{ backgroundColor: color }}>
                    {!disableBackButton && (
                        <div className="grid-start">
                            <button type="button" className="btn-back white" onClick={handleGoBack}>
                                <span>
                                    <Trans>뒤로가기</Trans>
                                </span>
                            </button>
                        </div>
                    )}
                    <h1 className="bs-title">
                        <a href={JAVASCRIPT_VOID} onClick={handleTitleClick}>
                            {station_name}
                        </a>
                    </h1>
                    <div className="bs-util">
                        {is_login && isWebview && is_owner && (
                            <ModalWindow
                                classNames={{
                                    modal: "ui-pop-m bs-layer",
                                }}
                                trigger={
                                    <button type="button" className="btn-write">
                                        <Trans>글쓰기</Trans>
                                    </button>
                                }
                            >
                                {(close) => (
                                    <div className="pop-body pop-gnb">
                                        <ButtonLink type="button" className="btn-write" to={`/${global.bj_id}/post/write`}>
                                            <Trans>글쓰기</Trans>
                                        </ButtonLink>
            
                                        <button
                                            type="button"
                                            className="btn-upload"
                                            onClick={() => {
                                                    window.location.href = `${APP_SCHEME}vod/upload`;
                                                    close();
                                                }
                                            }
                                        >
                                            <Trans>VOD 업로드</Trans>
                                        </button>
                                    </div>
                                )}
                            </ModalWindow>
                        )}
                        {is_login && !(isWebview && is_owner) && (
                            <ButtonLink type="button" className="btn-write" to={`/${global.bj_id}/post/write`}>
                                <span>
                                    <Trans>글쓰기</Trans>
                                </span>
                            </ButtonLink>
                        )}
                        <ButtonLink type="button" className="btn-search" to={`/${global.bj_id}/search`}>
                            <span>검색</span>
                        </ButtonLink>
                        <ModalWindow
                            classNames={{
                                modal: "ui-pop-m bs-layer",
                            }}
                            trigger={
                                <button type="button" className="btn-login">
                                    <span>상세정보</span>
                                </button>
                            }
                        >
                            {(close) => (
                                <div className="pop-body pop-gnb">
                                    <ul>
                                        {!isWebview && (
                                            <li>
                                                <a href={DOMAIN_URL}>
                                                    <Trans>SOOP 홈</Trans>
                                                </a>
                                            </li>
                                        )}
                                        {is_login && is_owner === false && (
                                            <li>
                                                <a href={`/${user_id}`}>
                                                    <Trans>내 채널</Trans>
                                                </a>
                                            </li>
                                        )}
                                        {is_login && is_best_bj && (
                                            <li>
                                                <a href={BEST_BJ_DOMAIN} target="_blank">
                                                    <Trans>베스트 스트리머 게시판 바로가기</Trans>
                                                </a>
                                            </li>
                                        )}
                                        {is_login && is_partner_bj && (
                                            <li>
                                                <a href={PARTNER_BJ_DOMAIN} target="_blank">
                                                    <Trans>파트너 스트리머 게시판 바로가기</Trans>
                                                </a>
                                            </li>
                                        )}
                                        {is_login && is_af_supporters_bj && (
                                            <li>
                                                <a href={SUPPORTERS_DOMAIN} target="_blank">
                                                    <Trans>SP서포터즈 게시판 바로가기</Trans>
                                                </a>
                                            </li>
                                        )}


                                        {is_login && (
                                            <li>
                                                <Link
                                                    type="button"
                                                    onClick={handleSearchMyBoard(user_id)}
                                                    to={{
                                                        pathname: `/${global.bj_id}/search`,
                                                        state: { searchType: STYPE_HASHTAG },
                                                        search: `keyword=${user_id}&field=${NICKNAME_USER_ID}`,
                                                    }}
                                                >
                                                    <Trans>내가 작성한 글 보기</Trans>
                                                </Link>
                                            </li>
                                        )}
                                        {!is_owner && (
                                            <li>
                                                <ButtonLink
                                                    to={`/${global.bj_id}/report`}
                                                    state={{
                                                        common_idx: 0,
                                                        board_type: 206,
                                                        writer_id: station_id,
                                                        writer_nick: station_nick,
                                                        title: '',
                                                        is_report_station : true,
                                                    }}
                                                    isLogin={is_login}
                                                >
                                                    <span>
                                                        <Trans>채널 신고하기</Trans>
                                                    </span>
                                                </ButtonLink>
                                            </li>
                                        )}
                                        {/* 채널 차단하기 - 안드로이드 && (해외 || 7.11.3 미만 버전) */}
                                        {!is_owner && isAndroidWebview() && (!getAndroidCompareVersion("7.11.3") || lang != "ko") && (
                                            <li>
                                                <Link
                                                    type="button"
                                                    onClick={() => {handleTargetBlind(0, station_id, close)}}
                                                >
                                                <Trans>이 채널 숨기기</Trans>
                                                </Link>
                                            </li>
                                        )}
                                        
                                        {/* BJ 숨기기 - 안드로이드 && 국내 && 7.11.3 이상 버전 */}
                                        {!is_owner && getAndroidCompareVersion("7.11.3") && lang == "ko" && (
                                            <li>
                                                <Link
                                                    type="button"
                                                    onClick={() => {handleTargetHidden(0, station_id, station_nick, close)}}
                                                >
                                                <Trans>스트리머 숨기기</Trans>
                                                </Link>
                                            </li>
                                        )}
                                        
                                        {is_login && (
                                            <li>
                                                <a href={getLogoutUrl()}>
                                                    <Trans>로그아웃</Trans>
                                                </a>
                                            </li>
                                        )}
                                        {!is_login && (
                                            <li>
                                                <a href={getLoginUrl()}>
                                                    <Trans>로그인</Trans>
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            )}
                        </ModalWindow>
                    </div>
                </section>
            </header>
        );
    } else {
        return (
            <>
                <header id="af-header" className={classNames({ "loading-header": loading })} style={headerStyle}>
                    <div className="inner_header">
                        <h1>
                            <a href={DOMAIN_URL} alt="">
                                {""}
                            </a>
                        </h1>
                        {/* 검색 */}
                        <AfreecaSearchBar key={keyword} />

                        {/* //검색 */}
                        <div className="bs-util">
                            {is_login && (
                                <div className="bs-favor">
                                    <ReactPopup
                                        offsetX={6}
                                        trigger={
                                            <button type="button" className="favor" tip={t("즐겨찾기")}>
                                                <span>
                                                    <Trans>즐겨찾기</Trans>
                                                </span>
                                            </button>
                                        }
                                    >
                                        <Favorites />
                                    </ReactPopup>
                                </div>
                            )}
                            <div><a
                                href={`${STBBS_DOMAIN}/vodupload/index.php?bj_id=${global.bj_id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "none" }}
                            >
                                <button type="button" className="upload" tip={t("VOD 업로드")}>
                                    <span>
                                        <Trans>VOD 업로드</Trans>
                                    </span>
                                </button></a>
                                {toolTipShow && (
                                    <div id="FeedLayerToolTip" className="tooltip" style={{display: "block"}}>
                                        <p>
                                            <Trans>
                                                60초 이하의 <br/> 세로 영상을 업로드하여<br/> Catch 서비스를 즐겨보세요!
                                            </Trans>
                                        </p>
                                        
                                        <button type="button" className="close" onClick={handleBtnToolTipCloseClick}>
                                            닫기
                                        </button>
                                    </div>
                                )}
                            </div>
                            <BroadCastArea />
                            <LoginArea />
                        </div>
                    </div>
                </header>
            </>
        );
    }
}
