import compareVersions from "compare-versions";
import { UCC } from "constant/board";
import {
    BJ_API_DOMAIN,
    ADBALLOON_DOMAIN,
    DOMAIN_URL,
    ITEM_MOBILE_DOMAIN,
    LOGIN_DOMAIN,
    NOTE_DOMAIN,
    OLD_LIVE_DOMAIN,
    PROFILE_IMG_DOMAIN,
    STATION_DOMAIN,
    VOD_PLAYER_DOMAIN,
    IMG_DOMAIN,
    RES_DOMAIN,
    STBBS_DOMAIN,
    MOBILE_DOMAIN,
    PLAY_DOMAIN,
    EVENT_API_DOMAIN,
    OLD_STATION_DOMAIN,
    MOBILE_AUTH_DOMAIN,
    APP_SCHEME, DOMAIN,
} from "constant/config";

import { getDefaultMessage } from "constant/error";
import { COMMENT_HASH, COMMENT_HASH_NOTI, REPLY_HASH, REPLY_HASH_NOTI } from "constant/hash";
import { POST } from "constant/method";
import { toastr } from "components/toastr";
import i18next from "i18next";
import querystring from "querystring";
import cookie from "react-cookies";
import { isAndroid, isEdge, isIE, isIOS, isMacOs, isMobile, isWindows, osVersion, mobileModel } from "react-device-detect";
import store from "store";
import axios from "libs/axios";
import moment from "moment";
import { CATCHSTORY, CATCH } from "constant/filetype";

export function displayNumber(num = 0) {
    const lang = document.documentElement.lang;

    let si = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: i18next.t("천") },
        { value: 1e4, symbol: i18next.t("만") },
        { value: 1e8, symbol: i18next.t("억") },
    ];

    // 영여, 태국, 베트남어는 천/백만 단위
    if (["vi", "en", "th"].indexOf(lang) > -1) {
        si = [{ value: 1, symbol: "" }, { value: 1e3, symbol: i18next.t("천") }, { value: 1e6, symbol: i18next.t("백만") }];
    }

    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }

    // 숫자 소숫점 두자리 부터 버림 정책 적용 (20240214)
    let floorNum = Math.floor((num/ si[i].value).toFixed(3) * 10) / 10;
    return (floorNum.toString()).replace(rx, "$1") + si[i].symbol;
}

export function formatBytes(a, b) {
    if (0 === a) return "0 Bytes";
    let c = 1024,
        d = b || 2,
        e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        f = Math.floor(Math.log(a) / Math.log(c));
    return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
}

export function makeStationImageHtml(szUrl) {
    return '<div><img src="' + szUrl + '" class="image"><p/></div>';
}

/**
 * 배열을 size 조각으로 나눔
 * @param array items
 * @param integer size
 */
export function chunksItems(items, size = 4) {
    if (!items || items.length === 0) {
        return [];
    }
    let ul = [];

    for (let i = 0; i < items.length; i += size) {
        ul.push(items.slice(i, size + i));
    }
    return ul;
}

/**
 * 줄바꿈을 <br> 태그로 변경
 * @param string str
 */
export function nl2br(str) {
    return str && str.replace(new RegExp("\r?\n", "g"), "<br />");
}

export function classSwitch(pcClassName, mobileClassName) {
    return isMobile ? mobileClassName : pcClassName;
}

/**
 * 로컬 세션 저장
 * @param {*} keyName
 * @param {*} defaultValue
 */
export function getSessionItem(keyName, defaultValue) {
    let value = sessionStorage.getItem(`${global.bj_id}_${keyName}`);
    if (value) {
        return JSON.parse(value);
    } else {
        return defaultValue;
    }
}
export function setSessionItem(keyName, keyValue) {
    if (keyValue === undefined) {
        return false;
    }
    sessionStorage.setItem(`${global.bj_id}_${keyName}`, JSON.stringify(keyValue));
    return keyValue;
}

/**
 * 초를 시간으로 표시
 * @param {*} seconds
 */
export function secondToTime(sec) {
    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - hours * 3600) / 60);
    let seconds = Math.floor(sec) - hours * 3600 - minutes * 60;
    let time = "";

    if (hours !== 0) {
        time = hours + ":";
    }

    time += hours > 0 ? String(minutes).padStart(2, '0') :  String(minutes);
    time += ":";
    time += String(seconds).padStart(2, '0');
    return time;
}

export const getLogoutUrl = () => {
    if (isWebview) {
        return `${APP_SCHEME}logout`;
    } else {
        return `${LOGIN_DOMAIN}/app/LogOut.php?request_uri=${window.encodeURIComponent(window.location.href)}`;
    }
};

export const isWebview = (() => {
    var uagent = navigator.userAgent.toLocaleLowerCase();
    return (uagent.indexOf("afreeca webview") > -1 || uagent.indexOf("afreeca up webview") > -1 || uagent.indexOf("sooplive webview") > -1 || uagent.indexOf("sooplive up webview") > -1)  ? true : false;
})();

export const getWebviewVersion = () => {
    var uagent = navigator.userAgent.toLocaleLowerCase();
    var regex = /(afreeca|sooplive) webview\d?\/(\d+\.\d+.\d+)/gi;
    var matchs = regex.exec(uagent);
    return matchs ? matchs[2] : null;
};

// 안드로이드 웹뷰 체크
export const isAndroidWebview = () => {
    return isAndroid && isWebview;
};

// 안드로이드 웹뷰 + 아프리카 앱 버전 분기
export const getAndroidCompareVersion = (version) => {
    return isAndroid && isWebview && (compareVersions(getAppVersion(), version) >= 0);
};

export const isKitkat = (() => {
    return isAndroid && osVersion.indexOf("4.4") === 0 ? true : false;
})();

// 캐치 스토리 안드로이드 os 13 미만 버전에는 블러처리 때문에 버벅임 현상 발생
export const isNonBlur = () => {
    return isAndroid && parseInt(osVersion) < 13;
};

export const checkSupportUpload = () => {
    if (isWebview && isKitkat) {
        alert("안드로이드 4.4 버전은 파일 업로드 기능을 제공하지 않습니다.\n기본 웹 브라우저로 이용해주시길 바랍니다.");
        return false;
    }
    return true;
};

/**
 * 쪽지보내기
 * @param {string} list
 */
export const sendNote = (list) => {
    const { is_login } = store.getState().authenticationReducer;
    if (is_login) {
        if (isMobile) {
            let szUserId = list;
            window.open(`${MOBILE_DOMAIN}/#/note/write2/${szUserId}`);
        } else {
            let szUserId = "";
            if (typeof list === "object") {
                szUserId = list.join(",");
            } else if (typeof list === "string") {
                szUserId = list;
            } else {
                console.error("The ID must be String or Array to send mail.");
                return false;
            }
            let oWindow = window.open(
                `${NOTE_DOMAIN}/app/index.php?page=write&id_list=${szUserId}`,
                "noteWriteWindow",
                "left=10,top=10,width=400,height=440,marginwidth=0,marginheight=0,resizable=0,scrollbars=no",
            );
            if (!oWindow) alert(i18next.t("차단된 팝업을 허용해주세요."));
            else oWindow.focus();
        }
    } else {
        if (window.confirm(i18next.t("로그인이 필요합니다. 로그인 하시겠습니까?"))) {
            goLogin();
        }
    }
};

/**
 * 채널 URL
 * @param {*} user_id
 */
export const getStationUrl = (user_id) => {
    return `${DOMAIN_URL}/${user_id}`;
};

/**
 * unicode를 문자로 변경
 * @param {*} user_id
 */
export function uniCodeToChrs(str) {
    const regex = /&#([0-9]{3,6});/g;
    const newstr = str.replace(regex, function(str, p1) {
        return String.fromCharCode(parseInt(p1, 10));
    });
    return newstr;
}

export const getSysType = () => {
    if (isMobile) {
        return isIOS ? "ios" : "aos";
    } else {
        return "web";
    }
};

export const isSupportPlayStoreInApp = () => {
    // 안드로이드 웹뷰 6.13.3 이상 앱 버전 플레이스토어 설치 된 경우 ogq 마켓 미노출
    const version = getAppVersion();
    const isPlayStore = inAppPlayStore();

    return (compareVersions(version, "6.13.3") >= 0 && isPlayStore == "Y" && isAndroid && isWebview);
}

/**
 * 구독하기
 * @param {*} bj_id
 */
export const popupSubscription = async (bj_id, tierUpgrade) => {
    if (!isWebview) {
        // 웹
        window.open(
            `${OLD_STATION_DOMAIN}/app/gift_subscription.php?bjId=${bj_id}&sysType=${getSysType()}&location=station${tierUpgrade ? '&itemId=742565' : ''}`,
            "gift_popup",
            "width=320,height=240,scrollbars=yes",
        );
    } else {
        popupSubscriptionMobile();
    }
};

/**
 * 구독하기 인앱결제
 * @param {*} bj_id 
 * @returns 
 */
 export const popupSubscriptionMobile = async () => {
    const { station = {}, profile_image = "" } = store.getState().stationReducer.stationInfo.data;
    const { user_nick = "" } = station;
    const { user_id } = store.getState().authenticationReducer;
    /*
     * AOS(기존) iOS(신규) 구독 버튼 추가 및 구독 페이지 UI 연결
     * 공통 파라미터 : receiverId, receive_nick, iOS : bj_image (프로필 이미지)
     */

    try {
        /**
         * 구독 flow
         * 1. 선물받은 구독권 보유 여부(api/subscription/a/info) 확인
         * 2. 없을 경우 마켓, 인앱 결제 활성화 체크
         * 3. 활성화 여부 Y 일 경우 구독 스킴 open
         */

        /**
         * 22-03-04 Google InApp 결제 작업에서 Android도 bj_image 추가
         * 구독 정보 API(subscription/a/info) Android 앱 내부에서도 호출 하고 있어 제거 예정. is_subscribe 파라미터 추가
         */
        // SUBSCRIPTION_URL
        let subscription_url = `${APP_SCHEME}item/subscription?receiverId=${station.user_id}&receive_nick=${encodeURIComponent(station.user_nick)}&bj_image=${encodeURIComponent("https:" + profile_image)}`;

        /* if (isIOS) {
            let url = "https:" + profile_image;
            subscription_url += `&bj_image=${encodeURIComponent(url)}`;
        } */

        const { result = -1, data = [] } = await axios.post(`${ITEM_MOBILE_DOMAIN}/api/subscription/a/info`, querystring.stringify({bj_id: global.bj_id}));
        
        const {
            subscription_type = "",
            subscription_ticket_type = "",
            first_payment_date = "",
            expire_payment_date = "",
            rest_payment_date = 0,
            hold_flag = 0,
            prod_name = "",
            svc_coupon = "",
            message = "",
        } = data;

        /**
         * subscription_type: auto 자동결제 / ticket: 구독한 경우
         * hold_flag: 선물받은 구독권 유무 0(없음) 1(있음)
         */

        // 오류 발생 시
        if (result === -1) {
            throw new Error(message);
        }

        if (result === -1102) {
            const { regist_date = "", regist_term_date = "" } = data;
            let msg = i18next.t("{{user_nick}}님은 서비스 이용이 정지되어\n구독할 수 없습니다.\n\n", { user_nick : user_nick });
            msg += i18next.t("정지일") + ` : `;
            msg += `${regist_date}\n`;
            msg += i18next.t("해제일") + ` : `;
            msg += `${regist_term_date}`;
            throw new Error(msg);
        }
        
        /**
           월 정기결제, 정기 구독권 : 얼럿 미노출 및 구독페이지 이동 o
         */

        // AOS 일 경우에 인앱 결제 어드민 요청   
        if (isAndroid) {
            const isActiveInApp = await getActiveInAppPayment() || false;
            const isPlayStore = inAppPlayStore();
            const store = inAppStore();
            subscription_url += `&activeInApp=` + (isActiveInApp && (isPlayStore == "Y" ? true : false));
            subscription_url += `&isSubscribed=` + (subscription_type == null || subscription_type === "" ? false : true);

            if(store) {
                subscription_url += `&store=` + store;
            }
        } 

        // 자동 결제 OR 선물 받은 구독권이 없을 경우 
        if (subscription_type === "auto" || hold_flag === 0) {
            // InApp 여부에 따른 구독 스킴 ON/OFF
            window.location.href = subscription_url;
            return;
        }

        // 선물받은 구독권이 있을 경우
        let msg = "";
        if (subscription_type === "ticket") {
            msg = i18next.t("{{user_nick}} 님에게 사용 가능한 선물 받은 구독권을 보유중입니다.\n\n", { user_nick : user_nick });
            msg += i18next.t("이용중인 구독권") + ` : `;
            msg += `${subscription_ticket_type}\n`;
            msg += i18next.t("구독 기간") + ` : `;
            msg += `${first_payment_date} ~ ${expire_payment_date}\n`;
            msg += i18next.t("(잔여일수 : {{rest_payment_date}}일)", { rest_payment_date : rest_payment_date }) + `\n`;
            msg += i18next.t("사용해서 구독하시겠습니까?") + `\n\n`;
            msg += i18next.t("사용할 구독권") + ` : `;
            msg += `${prod_name}`;
        } else {
            msg = i18next.t("{{user_nick}} 님에게 사용 가능한 구독권을 보유 중입니다\n사용 하시겠습니까?\n\n", { user_nick : user_nick });
            msg += i18next.t("사용할 구독권") + ` : `;
            msg += `${prod_name}`;
        } 

        // 선물 받은 구독권 사용
        if (!window.confirm(msg)) {
            window.location.href = subscription_url;
            return;
        }

        const { result: useResult = -1, data: useData = [] } = await fetchJson(
            `${ITEM_MOBILE_DOMAIN}/api/subscription/a/giftUse`,
            {
                method: POST,
                credentials: "include",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: querystring.stringify({ send_id: user_id, recv_id: global.bj_id, svc_coupon, location: "station" }),
            },
        );
        const { message: useMessage = "", item_name = "", period = "", remain_days = 0 } = useData;

        // 오류 발생 시
        if (useResult === -1) {
            throw new Error(useMessage);
        }

        msg = i18next.t("{{user_nick}}님에게\n선물 받은 구독권을 사용하였습니다.\n\n", { user_nick : user_nick });
        msg += i18next.t("이용중인 구독권") + ` : `;
        msg += `${item_name}\n`;
        msg += i18next.t("구독 기간") + ` : `;
        msg += `${period}\n`;
        msg += i18next.t("(잔여일수 : {{rest_payment_date}}일)", { rest_payment_date : remain_days });

        alert(msg);        
    } catch (error) {
        toastr.error(error.message, {
            timeOut: 2000,
        });
    }
 }

/**
 * 스티커 선물
 */
export const popupGiftSticker = (bj_id) => {
    window.open(
        `${OLD_STATION_DOMAIN}/app/pop_gift_sticker.php?szBjId=${bj_id}&sys_type=${getSysType()}&location=station`,
        "gift_popup",
        "width=320,height=240,scrollbars=yes",
    );
};

/**
 * 애드벌룬 후원하기
 */
export const goGiftAdBalloon = (bjId) => {
    const station_no = store.getState().stationReducer.stationInfo.data.station.station_no;
    let url = `${ADBALLOON_DOMAIN}/app/api/banner.php?broad_system=station&bj_id=${bjId}&station_no=${station_no}`;
    let openPopupId = null;
    if (isWebview) {
        //광고ID 추가
        const ua = navigator.userAgent;
        const ret = /adid=([0-9A-Za-z\-]+);/g.exec(ua);
        const adid = ret ? ret[1] : "ADID_DISABLED";
        const webviewUrl = `${url}&adid=${adid}`;

        // 안드로이드 웹뷰 (안드로이드 내 새창의 경우 버그가 존재하여 외부 브라우저로 이동)
        if (isAndroid) {
            /**
             * 안드로이드의 경우 모웹으로 넘어가기 때문에 애드벌룬 선물 처리 후 팝업창 종료가 아니라 유지하기 위해 파라미터 값 전달
             */
            const androidUrl = webviewUrl + `&os=aos_app`;
            // 선물 이벤트 외부 브라우저로 가는 이슈가 있어 채널 웹뷰로 이동되도록 스킴 변경
            openPopupId = window.open(`${APP_SCHEME}browser/station?url=${encodeURIComponent(androidUrl)}`, "gift_popup", "width=508,height=636");
        } else {
            openPopupId = window.open(webviewUrl, "gift_popup", "width=508,height=636");
        }
    } else {
        // PC, 모바일 웹
        openPopupId = window.open(url, "gift_popup", "width=508,height=636");
    }

    return openPopupId;
};

/**
 * 별풍선 선물하기
 */
export const goGiftStarBalloon = (bjId) => {
    let openPopupId = null;

    if (!isMobile) {
        // 웹
        openPopupId = window.open(
            `${OLD_STATION_DOMAIN}/app/gift_starballoon.php?szBjId=${bjId}&szWork=BJ_STATION&sys_type=${getSysType()}&location=station`,
            "gift_popup",
            "width=520,height=550,scrollbars=yes",
        );

        
    } else {
        if (isIOS && isWebview) {
            // IOS 모바일 앱 (별풍선 사용 시 심사 실패) 후원하기로 변경
            const params = {
                szBjId: bjId,
                nGiftNo: getSessionItem("nGiftNo") || "",
                szAppLocation: getSessionItem("szAppLocation") || "",
                szCodeType : "starballoon_station",
                szContentsType : "station",
                szOs : "ios"
            };

            /**
             * 후원하기 페이지
             */
            let url = `${MOBILE_DOMAIN}/item/a/support`;
            url += "?" + querystring.stringify(params);
            const version = getAppVersion();
            if (compareVersions(version, "5.15.0") >= 0) {
                openPopupId = window.open(url);
            } else {
                window.location.href = `${APP_SCHEME}browser/webview?url=${encodeURIComponent(url)}`;
            }
        } else {
            /**
             * 별풍선 선물하기 페이지
             * 구글플레이 인앱 결제 isPlayStore header 추가
             * 안드로이드 특정 웹뷰(ex.afreeca://browser/external) 호출 시 userAgent 값이 정상 전달 안되는 이슈 있음
             * 파라미터로 앱 버전 추가해서 전달
             */

            const version = getAppVersion();
            const isPlayStore = inAppPlayStore();
            const store = inAppStore();
            let url = `${MOBILE_DOMAIN}/item/a/starballoongift?szBjId=${bjId}&r=${Math.random()}`;
            
            if (isWebview) {
                //url = "http:" + url; // ios에서 afreeca: 스킴 사용 시 http/https 스킴이 없을 경우 동작하지 않음
                url += "&ref=webview";
                url += '&isPlayStore=' + isPlayStore;
                url += '&appVersion=' + (compareVersions(version, "1.0.0") >= 0 ? version : 0);

                if(store) {
                    url += '&store=' + store;
                }

                url += '&szOsVer=' + osVersion;
                url += '&szModel=' + mobileModel;
                url += '&szCodeType=starballoon_station';
                url += '&szContentsType=station';
                url += '&szOs=android'; //2025 3/10 인앱결제관련 파라미터 aos - andorid로 변경

                window.location.href = `${APP_SCHEME}browser/external?url=${encodeURIComponent(url)}`;
            } else {
                openPopupId = window.open(url);
            } 
        }
    }

    return openPopupId;
};

/**
 * 구글 플레이스토어 
 * inApp 결제 활성화 여부 체크
 */
export const getActiveInAppPayment = async () => {
    // 인앱 결제 활성화 체크 여부 확인
    const params = {
        work: "IN_APP",
        type: "subscription",
        os: "android",
        version: getAppVersion() 
    }

    const { RESULT = -1, DATA = [] } = await axios.get(`${OLD_LIVE_DOMAIN}/api/item_info/index.php`, { params });

    if (RESULT !== 1) {
        return false;
    }

    return DATA;
}

/*
 * 별풍선 / 애드벌룬 세레모니 API 조회
 */

export const getCeremony = async (itemType = "STARBALLON") => {
    const params = {
        item_type: itemType
    };

    try {
        const response = await axios.get(`${BJ_API_DOMAIN}/${global.bj_id}/ceremony`, {params} );
        return response;

    } catch (error) {
        return false;
    }
}

/**
 * 시그니처, 스토리 텔링 이미지 조회
 * 시그니처 > 시그니처 이모티콘 등록
 * 스토링 텔링 > 100개 쏠때 나오는 별풍선 이미지
 */
export const getCeremonyImg = (oCeremony) => {
    const {giftType, giftCnt, ceremony_starballoon, ceremony_adballon_img } = oCeremony;
    const {signature_balloon, stars_balloon} = ceremony_starballoon;
    
    let imgUrl = "";
    let defaultImg = "";
    let isStoryTelling = false;
    let errorUrl = `${RES_DOMAIN}/new_player/items/` + (isMobile ? "thumb/balloon_" :"ba_step");
    if (giftType === "STARBALLOON" && giftCnt > 0) {
        if (signature_balloon) { // 시그니처 별풍선
            signature_balloon.forEach((list) => {
                if (Number(list.number) === Number(giftCnt)) {
                    imgUrl = list.imageUrl;
                    isStoryTelling = true;
                }
            });
        }

        if (stars_balloon) { // 스타즈 별풍선
            stars_balloon.forEach((list) => {
                if (Number(list.number) === Number(giftCnt)) {
                    imgUrl = list.imageUrl;
                    isStoryTelling = true;
                }
            });
        }

        if (!signature_balloon && !stars_balloon) {
            // 스토리 텔링 없는 경우
            if (giftCnt > 0 && giftCnt < 10) { defaultImg = (isMobile ? '1.svg' : '1.png') }
            else if  (giftCnt >= 10 && giftCnt < 100) { defaultImg = (isMobile ? '2.svg' : '2.png' ) }
            else if  (giftCnt >= 100 && giftCnt < 500) { defaultImg = (isMobile ? '3.svg' : '3.png' ) }
            else if  (giftCnt >= 500 && giftCnt < 1000) { defaultImg = (isMobile ? '4.svg' : '4.png' ) }
            else if  (giftCnt >= 1000) { defaultImg = (isMobile ? '5.svg' : '5.png' )}
            imgUrl = errorUrl + defaultImg;

            if(!isMobile) {
                isStoryTelling = true;
            }
        }
    }

    /**
     * 채널 세레머니 이미지 모바일용으로 조회
     */
    if (giftType === "ADBALLOON" && giftCnt > 0 && ceremony_adballon_img !== "") {
        //imgUrl = `${RES_DOMAIN}/new_player/items/adballoon/ceremony/mobile_${giftCnt}.png`;
        imgUrl = `${RES_DOMAIN}/new_player/items/` + ceremony_adballon_img;
        isStoryTelling = true;
    }

    return {
        imgUrl: imgUrl,
        isStoryTelling: isStoryTelling
    };
}

/**
 * 시그니처 스토리 텔링 별풍선 애드벌룬 이미지 onError 기본 이미지 처리
 */
 export const handleCeremonyImgError = (e, giftType, giftCnt, defaultUrl = "") => {
    e.preventDefault();
    let defaultImg = "";
    let errorUrl = `${RES_DOMAIN}/new_player/items/` + (isMobile ? "thumb/balloon_" :"ba_step");
    if(giftType === 'STARBALLOON') {
        if (giftCnt > 0 && giftCnt < 10) { defaultImg = (isMobile ? '1.svg' : '1.png') }
        else if  (giftCnt >= 10 && giftCnt < 100) { defaultImg = (isMobile ? '2.svg' : '2.png' ) }
        else if  (giftCnt >= 100 && giftCnt < 500) { defaultImg = (isMobile ? '3.svg' : '3.png' ) }
        else if  (giftCnt >= 500 && giftCnt < 1000) { defaultImg = (isMobile ? '4.svg' : '4.png' ) }
        else if  (giftCnt >= 1000) { defaultImg = (isMobile ? '5.svg' : '5.png' ) }
    } 
    
    if (giftType === 'ADBALLOON') {
        //errorUrl = `${RES_DOMAIN}/new_player/items/img_gift_adballoon.png`;
        errorUrl = `${RES_DOMAIN}/new_player/items/adballoon/ceremony/mobile_0_1.png`;
        if(defaultUrl) {
            errorUrl = defaultUrl;
        }
    }
    e.target.src = errorUrl + defaultImg;
}


/**
 * 모바일 실명인증
 */
export const getRealname = () => {
    if (window.confirm(i18next.t("해당 서비스를 사용하기 위해서 정보통신망법에 따른 본인확인이 필요합니다.\n본인확인을 진행하겠습니까?"))) {
        if (isWebview) {
            const realname_url = `${MOBILE_AUTH_DOMAIN}/realname`;
            window.location = `${APP_SCHEME}realName/requireAuth?url=${window.encodeURIComponent(realname_url)}`;
        } else {
            window.open(`${MOBILE_AUTH_DOMAIN}/realname`);
        }
    }
};
/**
 * 로그인 페이지 URL
 */
export const getLoginUrl = () => {
    if (isWebview) {
        return `${APP_SCHEME}login/requireLogin`;
    } else if (isMobile) {
        return `${MOBILE_AUTH_DOMAIN}/login?url=${window.encodeURIComponent(window.location.href)}`;
    } else {
        return `${LOGIN_DOMAIN}/afreeca/login.php?szFrom=full&request_uri=${window.encodeURIComponent(window.location.href)}`;
    }
};

/**
 * 로그인 페이지
 */
export const goLogin = () => {
    window.location = getLoginUrl();
};
/**
 * 로그인 페이지
 */
export const goLogout = () => {
    if (isWebview) {
        return `${APP_SCHEME}logout`;
    } else {
        return `${LOGIN_DOMAIN}/afreeca/login.php?szFrom=full&request_uri=${window.encodeURIComponent(window.location.href)}`;
    }
};

export const getVodEditUrl = (vod) => {
    if (isMobile) {
        return `${MOBILE_DOMAIN}/vod/edit/a/index?szWork=edit_title&szLocation=station&nStationNo=${vod.station_no}&nBbsNo=${vod.bbs_no}&nTitleNo=${vod.title_no}`;
    } else {
        return `${STBBS_DOMAIN}/vodupload/index.php?szWork=edit_title&szLocation=station&nStationNo=${vod.station_no}&nBbsNo=${vod.bbs_no}&nTitleNo=${vod.title_no}`;
    }
};

export const preview = (params) => {
    var popup = window.open("", "popPreview", "height=774, width=947, scrollbars=yes, resizeable=yes");
    if (popup == null) {
        alert(i18next.t("'미리보기'는 브라우저의 팝업 차단 해제 후 이용할 수 있습니다."));
        return;
    }
    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", `${STBBS_DOMAIN}/app/preview_bbs_new.php`);
    form.setAttribute("target", "popPreview");

    Object.keys(params).forEach((key) => {
        var input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = params[key];
        form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
};

export const goLive = (broad) => (e) => {
    e.preventDefault();
    liveInflowLog(broad.user_id, broad.broad_no, ["separate", "station", "thumbnail"]);
    if (isWebview) {
        window.location = `${APP_SCHEME}player/live?user_id=${broad.user_id}&broad_no=${broad.broad_no}`;
    } else {
        window.open(`${PLAY_DOMAIN}/${broad.user_id}/${broad.broad_no}`);
    }
};

export const handleFetchErrors = (response) => {
    if (!response.ok) {
        return response.json().then((json) => {
            return Promise.reject(json);
        });
    }
    return response;
};

export const handleFailedToFetch = (error) => {
    return Promise.reject({
        message: getDefaultMessage(),
    });
};

export const fetchJson = (input, init) => {
    return fetch(input, init)
        .then(handleFetchErrors, handleFailedToFetch)
        .then((response) => response.json());
};

export const documentScrollTop = () => {
    const someElement = document.documentElement.scrollTop && isEdge === false ? document.documentElement : document.body;
    someElement.scrollTop = 0;
};

// 글자수 BYTE로
export const countByte = (word) => {
    var bytes = 0;
    let idx;
    for (idx = 0; idx < word.length; idx++) {
        if (word.charCodeAt(idx) > 128) {
            bytes += 2;
        } else {
            bytes += 1;
        }
    }
    return bytes;
};

/*
 * 문자열 nMax byte에 해당하는 문자의 갯수 구하기
 * data: 문자열
 * nMax: 문자열 최대 바이트수
 */
export const getTextCount = (data, nMax) => {
    var nBytes = 0;
    var nLength = 0;
    var nIdx = 0;
    for (nIdx = 0; nIdx < data.length; nIdx++) {
        if (data.charCodeAt(nIdx) > 128) {
            nBytes += 2;
            nLength++;

            if (nBytes > nMax) {
                nLength--;
                return nLength;
            }
        } else {
            nBytes++;
            nLength++;

            if (nBytes > nMax) {
                nLength--;
                return nLength;
            }
        }
    }
};

let change = false;
//error 발생하면 같은 메시지로는 업데이트 되지 않음
export const behindText = () => {
    change = !change;
    return change ? "" : String.fromCharCode(0x200b);
};

//error 발생하면 같은 메시지로는 업데이트 되지 않음
export const numberCommaString = (value) => {
    if (!value) {
        return 0;
    }
    if (isIE) {
        return Number(value)
            .toLocaleString()
            .replace(".00", "");
    } else {
        return Number(value).toLocaleString();
    }
};

export const getActiveNo = () => {
    const timestamp = new Date().getTime();
    return ((timestamp + 32400) / 86400) % 2;
};

export const getProfileImage2 = (user_id, ext, appendParam) => {
    switch (ext.toUpperCase()) {
        case "JPG":
            return `${PROFILE_IMG_DOMAIN}/LOGO/${user_id.substr(0, 2)}/${user_id}/${user_id}.jpg` + appendParam;
        case "WEBP_SMALL":
            return `${IMG_DOMAIN}/LOGO/${user_id.substr(0, 2)}/${user_id}/m/${user_id}.webp` + appendParam;
        case "default":
        default:
            return `${RES_DOMAIN}/images/svg/thumb_profile.svg`;
    }
}

export const getProfileImage = (user_id, errorCheck = false) => {
    if(!user_id) {
        return `${RES_DOMAIN}/images/svg/thumb_profile.svg`;
    }

    if(errorCheck) {
        return `${PROFILE_IMG_DOMAIN}/LOGO/${user_id.substr(0, 2)}/${user_id}/${user_id}.jpg`;
    }

    return `${IMG_DOMAIN}/LOGO/${user_id.substr(0, 2)}/${user_id}/m/${user_id}.webp`;
};

/**
 * IE11에서 localStorage가 안되는 경우가 있어 cookie로 맵핑합니다.
 * https://gist.github.com/juliocesar/926500
 */
export const localStorageFactory = (() => {
    try {
        return localStorage;
    } catch (e) {
        function Storage() {}
        Storage.prototype.setItem = function(sKey, sValue) {
            if (!sKey) {
                return;
            }
            document.cookie = escape(sKey) + "=" + escape(sValue) + "; expires=Tue, 19 Jan 2038 03:14:07 GMT; path=/";
        };
        Storage.prototype.getItem = function(sKey) {
            if (!sKey) {
                return null;
            }
            return unescape(
                document.cookie.replace(
                    new RegExp("(?:^|.*;\\s*)" + escape(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*"),
                    "$1",
                ),
            );
        };
        Storage.prototype.removeItem = function(sKey) {
            if (!sKey || !this.hasOwnProperty(sKey)) {
                return;
            }
            document.cookie = escape(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
            this.length--;
        };
        Storage.prototype.clear = function() {};
        return new Storage();
    }
})();

// 240524 GT : 블록한 bjid를 로컬 스토리지에 저장한다. -> 댓글 목록에서 차단 처리하기 위함
export const addBlockedStreamerId =  (bjId)=> {
    const { user_id } = store.getState().authenticationReducer;
    const allBlockedStreamers = JSON.parse(localStorageFactory.getItem("myBlockedStreamers_just_temp")||'{}');
    /*
    * {
    *   [user_id] : ['개똥이', '말똥이',], ...
    * }
    * */

    if(!allBlockedStreamers[user_id]){
        allBlockedStreamers[user_id] = [];
    }
    const myBlockedStreamerList = allBlockedStreamers[user_id];
    if(!myBlockedStreamerList.includes(bjId)){
        myBlockedStreamerList.push(bjId);
        localStorageFactory.setItem('myBlockedStreamers_just_temp', JSON.stringify(allBlockedStreamers));
    } else {
        // do nothing
    }
}
export const getMyBlockedStreamerList = () => {
    const { user_id } = store.getState().authenticationReducer;
    const allBlockedStreamers = JSON.parse(localStorageFactory.getItem("myBlockedStreamers_just_temp")||'{}');
    return allBlockedStreamers[user_id] || [];
}

export const getNickname = () => {
    const { stationInfo } = store.getState().stationReducer;
    const { data = {} } = stationInfo;
    const { station } = data;
    return station ? station.user_nick : "";
};

/**
 * CLICK로그
 */
export const setClickLogapi = (code_type, sendData, callback) => {
    const sysType = isWebview && isMobile ? "app" : "web";
    let data = Object.assign({ sztype: "CLICK", code_type: code_type, send_data: querystring.stringify(sendData), sys_type: sysType });
    //로딩
    return fetch(`${EVENT_API_DOMAIN}/set_log_api.php`, {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: querystring.stringify(data),
        credentials: "include",
    })
        .then(callback)
        .catch(callback);
};

/**
 * Simply compares two string version values.
 *
 * Example:
 * versionCompare('1.1', '1.2') => -1
 * versionCompare('1.1', '1.1') =>  0
 * versionCompare('1.2', '1.1') =>  1
 * versionCompare('2.23.3', '2.22.3') => 1
 *
 * Returns:
 * -1 = left is LOWER than right
 *  0 = they are equal
 *  1 = left is GREATER = right is LOWER
 *  And FALSE if one of input versions are not valid
 *
 * @function
 * @param {String} left  Version #1
 * @param {String} right Version #2
 * @return {Integer|Boolean}
 * @author Alexey Bass (albass)
 * @since 2011-07-14
 */
export const versionCompare = function(left, right) {
    if (typeof left + typeof right != "stringstring") return false;

    var a = left.split("."),
        b = right.split("."),
        i = 0,
        len = Math.max(a.length, b.length);

    for (; i < len; i++) {
        if ((a[i] && !b[i] && parseInt(a[i]) > 0) || parseInt(a[i]) > parseInt(b[i])) {
            return 1;
        } else if ((b[i] && !a[i] && parseInt(b[i]) > 0) || parseInt(a[i]) < parseInt(b[i])) {
            return -1;
        }
    }

    return 0;
};

/**
 * 쿠키및 브라우져 셋팅 언어셋 리스트
 */
export const detectLanguages = () => {
    var langs = [cookie.load("_lang"), navigator.languages && navigator.languages[0], navigator.userLanguage];
    if (navigator.languages) {
        langs = langs.concat(navigator.languages.slice(1));
    }
    langs = langs.filter((val) => val); //없는 값 제거하는 필터링

    return langs;
};
/**
 * 지원가능한 언어셋
 */
export const allowLanguage = () => {
    // const possibleLanguages = ["ko", "en", "ja", "th", "zh", "zh-TW"];
    const possibleLanguages = ["ko"];
    let langs = detectLanguages();
    // 현재 노출 언어 정책은 사용자가 설정한 1순위 언어만 판단한다. (차순위 고려 x) 23.01
    let lang = langs[0];
    lang = lang.replace("_", "-");

    //중국어예외처러
    if (["zh-hk", "zh-mo", "zh-hant"].indexOf(lang.toLowerCase()) > -1) {
        lang = "zh-TW";
    }

    if (possibleLanguages.indexOf(lang) >= 0) return lang;

    if (lang.indexOf("-") >= 0) {
        lang = lang.split("-")[0];
        if (possibleLanguages.indexOf(lang) >= 0) return lang;
    }

    return "ko"; //기본값
};

export const liveInflowLog = (bj_id, broadNo, paths) => {
    inflowLog("LIVE", bj_id, broadNo, paths);
};
export const vodInflowLog = (bj_id, titleNo, paths, appendData = {}) => {
    inflowLog("VOD", bj_id, titleNo, paths, appendData);
};

/**
 * 진입로그
 * @param {string} code_type 타입
 * @param {string} bj_id
 * @param {number} contentNo 콘텐츠번호
 * @param {array} paths
 * @param {object} appendData paths외 추가 데이터 
 */
export const inflowLog = (code_type, bj_id, contentNo, paths = [], appendData = {}) => {
    let cookieName = "";
    let inflowLogData = [];
    const tmToday = new Date();
    const _au = cookie.load("_au");
    const path_key = _au + "_" + (!!contentNo && Number(contentNo) !== 0 ? contentNo : bj_id) + "_" + tmToday.getTime();
    const inflowPath = paths.reduce((accumulator, value, index) => {
        accumulator[`path${index + 1}`] = value;
        return accumulator;
    }, []);

    const sysType = isWebview ? "app" : isMobile ? "webm" : "web";
    const os = isWebview ? (isIOS ? "ios" : "aos") : isMacOs ? "mac" : "win";

    switch (code_type) {
        case "LIVE":
            cookieName = "LIN";
            inflowLogData = { bj_id, broad_no: contentNo, referer: window.location.href, ...inflowPath, os };
            break;
        case "VOD":
            cookieName = "VIN";
            inflowLogData = { bj_id, title_no: contentNo, referer: window.location.href, ...inflowPath, os };
            break;
        default:
            break;
    }

    if (typeof (appendData['story_no']) !== 'undefined') {
        inflowLogData.story_no = appendData['story_no'];
    }

    /**
     * 쿠키셋팅
     */
    tmToday.setTime(tmToday.getTime() + 10 * 1000); //10초 후 만료
    const cookieValue = querystring.stringify({ path_key, ...inflowPath, ...appendData });
    cookie.save(cookieName, cookieValue, { path: "/", expires: tmToday, domain: DOMAIN });

    /**
     * 예외처리 추가
     * - AOS, iOS 6.8.0 이상일 경우에만 웹에서 inflow_path는 안보내는 것으로 함 (6.7.0 이하 버전은 웹에서 보내짐)
     */
    const version = getAppVersion();
    if (!(isWebview && compareVersions(version, "6.8.0") >= 0)) {
        /**
         * 로그호출
         */
        fetch(`${EVENT_API_DOMAIN}/set_log_api.php`, {
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({
                sztype: "INFLOW_PATH",
                code_type,
                path_key,
                send_data: querystring.stringify(inflowLogData),
                sys_type: sysType,
            }),
            credentials: "include",
        }).then(() => {});
    }
};

/**
 * 웹뷰이고 공유하기 가능한 버전인지
 */
export const isWebbiewShareable = () => {
    if (isWebview) {
        const version = getAppVersion();
        if (isAndroid && compareVersions(version, "5.11.0") >= 0) {
            return true;
        } else if (isIOS && compareVersions(version, "5.11.0") >= 0) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};
/**
 * 채널 post 공유
 * @param {*} post
 */
export const webviewSharePost = (post) => {
    if(post.ucc && post.ucc.file_type === CATCHSTORY) {
        webviewShare(getPostUrl(post), Number(post.broad_date.split("-")[1])+i18next.t("월")+" "+Number(post.broad_date.split("-")[2])+i18next.t("일")+" "+i18next.t("스토리"));
    } else {
        webviewShare(getPostUrl(post), post.title_name);
    }
};

/**
 * 채널 재생목록 공유
 * @param playlist
 */
export const webviewSharePlaylist = (playlist) => {
    webviewShare(getPlaylistUrl(playlist), playlist.title);
};

/**
 * 공유
 * @param {*} post
 */
export const webviewShare = (url, title) => {
    window.location.href = `${APP_SCHEME}share?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`;
};

/**
 * 게시글  URL
 * @param {*} post
 */
export const getPostUrl = (post) => {
    if (post.board_type === UCC) {
        if (post.ucc.file_type === CATCH) {
            return `${VOD_PLAYER_DOMAIN}/${post.title_no}/catch`;
        }
        return `${VOD_PLAYER_DOMAIN}/${post.title_no}`;
    } else if (post.ucc && post.ucc.file_type === CATCHSTORY) {
        return `${VOD_PLAYER_DOMAIN}/${post.story_idx}/catchstory?o=1`;
    } else {
        return `${STATION_DOMAIN}/${global.bj_id}/post/${post.title_no}`;
    }
};

/**
 * 재생목록 url
 * @param playlist
 * @returns {string}
 */
export const getPlaylistUrl = (playlist) => {
    /**
     * 재생목록 첫번째 VOD가 삭제 된 경우 다음 VOD로 넘어갈 수 있도록 변경
     * bbs_title_no 가 아닌 vod_playlist_tbl.rep_title_no 로 참조
     */
    let { bbsTitle: post = {} } = playlist;
    post = post || {};
    return `${VOD_PLAYER_DOMAIN}/${playlist.rep_title_no || 0}/playlist/${playlist.idx}`;
};

/**
 * 웹뷰의 버전
 */
export const getAppVersion = () => {
    var aMatch = navigator.userAgent.toLocaleLowerCase().match(/(afreeca|sooplive) webview2?\/([0-9.]+)/);
    return aMatch ? aMatch[2] : "1.0.0";
};

/**
 * 인앱 결제 가능 여부 app 내 header
 * @param {*} duration 
 */
export const inAppPlayStore = () => {
    const ua = navigator.userAgent;
    const isPlayStore = /isPlayStore=([yYnN]);?/gi.exec(ua) || '';

    return (isPlayStore !== '' ? isPlayStore[1].toUpperCase() : '');
}
export const youtubeDurationToSeconds = (duration) => {
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    // Remove PT from string ref: https://developers.google.com/youtube/v3/docs/videos#contentDetails.duration
    duration = duration.replace("PT", "");

    // If the string contains hours parse it and remove it from our duration string
    if (duration.indexOf("H") > -1) {
        let hours_split = duration.split("H");
        hours = parseInt(hours_split[0]);
        duration = hours_split[1];
    }

    // If the string contains minutes parse it and remove it from our duration string
    if (duration.indexOf("M") > -1) {
        let minutes_split = duration.split("M");
        minutes = parseInt(minutes_split[0]);
        duration = minutes_split[1];
    }

    // If the string contains seconds parse it and remove it from our duration string
    if (duration.indexOf("S") > -1) {
        let seconds_split = duration.split("S");
        seconds = parseInt(seconds_split[0]);
    }

    // Math the values to return seconds
    return hours * 60 * 60 + minutes * 60 + seconds;
};

export const gtag = (category, action, label, value) => () => {
    window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value: value,
    });
};

/**
 * 다크모드 유뮤
 */
export const isDark = () => {
    return cookie.load("theme") === "dark" ? true : false;
};

/**
 * parsing boolean
 * @param value
 * @returns {boolean}
 */
export const parseBoolean = (value) => {
    if (typeof value === "undefined") {
        return false;
    }

    if (typeof value === "object") {
        return value !== null;
    }

    if (typeof value === "number" || typeof value === "bigint") {
        return value !== 0;
    }

    if (typeof value === "string") {
        return value.toLowerCase() !== "false";
    }

    if (typeof value === "boolean") {
        return value;
    }

    return true;
};

/**
 * 현재 플랫폼(OS) 조회
 */
export const getPlatForm = () => {
    let os = "";
    if (isMobile) {
        if (isAndroid) {
            os = "ANDROID";
        } else {
            os = "IOS";
        }
    } else {
        if (isWindows) {
            os = "WINDOWS";
        } else if (isMacOs) {
            os = "MAC";
        }
    }
    return os;
};

/**
 * 모바일 게시물 이미지 클릭허용 유무 (새창,확대기능)
 */
export const isImageOpenAllowed = () => {
    if (isMobile) {
        if (isWebview) {
            const version = getAppVersion();
            if (compareVersions(version, "5.22.0") >= 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    } else {
        return false;
    }
};

/**
 * 복사 붙여넣기시 태그제외하기
 */
export const copyPasteEdit = (e, replace) => {
    e.preventDefault();
    e.stopPropagation();
    let clipboardData = e.clipboardData || window.clipboardData;
    let content = clipboardData.getData(isIE ? "text" : "text");
    if (isIE) {
        if (window.getSelection) {
            var newNode = document.createElement("span");
            newNode.innerHTML = content.replace(/(?:\r\n|\r|\n)/g, replace);
            var selRange = window.getSelection().getRangeAt(0);
            selRange.deleteContents();
            selRange.insertNode(newNode);
        }
    } else {
        content = content.replace(/(?:\r\n|\r|\n)/g, replace);

        document.execCommand("insertHtml", false, content); //=> event를 못걸러냄
        // document.execCommand('paste', false, content);// => event를 걸러냄

        return false;
    }
}

export const fetchAdBalloonInfo = () => {
    let url = `${ADBALLOON_DOMAIN}/app/api/savings.php?type=info`;

    return fetch(url, {
        credentials: "include",
    })
        .then((response) => response.json());

}

export const getNotiHashRegExr = (state, type) => {

    switch (type) {
        case COMMENT_HASH:
            return state.match(/^(#comment)([0-9]+)$/) || '';
        case COMMENT_HASH_NOTI:
            return state.match(/^(#comment(_noti)?)([0-9]+)$/) || '';

        case REPLY_HASH:
            return state.match(/^(#reply)([0-9]+)$/) || '';
        case REPLY_HASH_NOTI:
            return state.match(/^(#reply(_noti)?)([0-9]+)$/) || '';

        default:
            break;
    }

    return '';
}
/**
 * 애드벌룬 이벤트 활성화되었는지 체크 
 */
export const getActiveEvent = async() => {
    const params = querystring.stringify({ type: "active_bj_check", bj_id: global.bj_id });
    let url = `${ADBALLOON_DOMAIN}/app/api/adballoon_event.php?${params}`;

    return await fetch(url, {
        credentials: "include",
    })
        .then((response) => response.json())
        .catch((error) => error);
}

/*
 * vod 플레이어 스킴
 */
export const getVodPlayerScheme = (station_no,bbs_no,title_no,file_type,orderBy) => {
    const version = getAppVersion();
    
    if (file_type === "CATCH" && compareVersions(version, "6.14.2") >= 0) {
        
        let szListDataType = get_list_data_type("station"); //추천 데이터 로그

        let catchCreated = localStorageFactory.getItem("catchCreated") || true;
        catchCreated = parseBoolean(catchCreated);

        return `${APP_SCHEME}player/catch?station_no=${station_no}&bbs_no=${bbs_no}&title_no=${title_no}&type=${file_type}&szLocation=station${szListDataType}&station_id=${global.bj_id}&show_user_created=${catchCreated}&order=${orderBy}`;
    }

    return `${APP_SCHEME}player/video?station_no=${station_no}&bbs_no=${bbs_no}&title_no=${title_no}&type=${file_type}`;
};

/*
 * 단일 캐치 스토리 플레이어 스킴 scheme or Link
 * nStoryIdx : 스토리 idx
 */
export const getOneStoryPlayerLink = (nStoryIdx) => {

    if(isWebview){
        return `${APP_SCHEME}player/catchstory?nStoryIdx=${nStoryIdx}&szLocation=station`;
    } else {
        return `${VOD_PLAYER_DOMAIN}/${nStoryIdx}/catchstory?szLocation=station`;
    }
};

/*
 * 캐치 스토리 플레이어 스킴 scheme
 * nStoryIdx : 스토리 idx
 * nStoryOrderInTotal : 스토리 전체 중에 해당 스토리 위치 (앱에서 원할한 재생을 위해 필요한 값)
 * nStoryTotal : 스토리 전체 개수 (앱에서 원할한 재생을 위해 필요한 값)
 * szOrderBy : 스토리 정렬 (reg_date, reg_date_asc)
 */
export const getStoryPlayerScheme = (nStoryIdx, nStoryOrderInTotal, nStoryTotal, orderBy) => {

    return `${APP_SCHEME}player/catchstory?nStoryIdx=${nStoryIdx}&nStoryOrderInTotal=${nStoryOrderInTotal}&nStoryTotal=${nStoryTotal}&szLocation=station&szOrderBy=${orderBy}`;
};

/*
 * 캐치 채널 스킴에 들어갈 추천 데이터 타입
 * 채널,채널 영역은 추천이 없으니 고정 데이터 들어감
 */
export const get_list_data_type = (detail_locatin="station") => {
    let szSchemeListData = "";
    let list_data_type = "";
    let list_data_detail = "";
    let rec_detail = "";

    switch(detail_locatin){
        case "station": //채널
            list_data_type = 'bj_related';
            list_data_detail = '["bj_related"]';
            rec_detail = '{"bj_related":0.0}';
            break;
    }

    list_data_detail = encodeURI(list_data_detail);
    rec_detail = encodeURI(rec_detail);

    szSchemeListData = `&list_data_type=${list_data_type}&list_data_detail=${list_data_detail}&rec_detail=${rec_detail}`;

    return szSchemeListData;
};

/**
 * store 구분자 값 (p: playstore, o: onestore, s: galaxy, etc: unknown or ios)
 * @return {string|boolean}
 */
export const inAppStore = () => {
    let uagent = navigator.userAgent;
    const store = /store\/([a-zA-Z]+);?/gi.exec(uagent) || '';

    return store ? store[1].toUpperCase() : false;
}


// 할인율
export const discountRate = (original_price = 0, discount_price = 0) => {

    return  Math.round( ( (original_price - (original_price - discount_price)) / (original_price) * 100) );
};

// 시작일 계산
export const calcurateDday = (broadcast_live) => {

    let liveStart = moment(broadcast_live.on_air_start_date).format("YYYY-MM-DD 00:00");
    liveStart = new Date(liveStart).getTime();
    let day = Math.ceil((liveStart - new Date().getTime()) / (60 * 60 * 24 * 1000));

    // -0 이상 나오면 안됨 dday 지난거
    if (day <= 0) return "D-day";

    return "D-" + day;
};

/**
 * 특정 태그의 값을 XML 문자열에서 추출
 * @param {string} tag - 찾고자 하는 태그 이름
 * @param {string} xml - XML 문자열
 * @param {string} [attr] - 특정 속성 이름 (선택 사항)
 * @returns {Array<{ attributes: Record<string, string>, value: string }>} 
 * - 태그의 속성과 값을 포함한 배열 (없으면 빈 배열 반환)
 */
export function getXmlTagValue(tag, xml, attr) {
    if (!tag || !xml) {
        console.warn(`유효하지 않은 입력: tag=${tag}, xml=${xml ? 'provided' : 'not provided'}`);
        return [];
    }

    try {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml, 'application/xml');

        // 파싱 에러 확인
        const parseError = xmlDoc.getElementsByTagName('parsererror');
        if (parseError.length > 0) {
            console.error('XML 파싱 실패:', parseError[0].textContent?.trim());
            return [];
        }

        const elements = xmlDoc.getElementsByTagName(tag);
        const results = [];

        for (let element of elements) {
            const attributes = {};
            for (let i = 0; i < element.attributes.length; i++) {
                const attrNode = element.attributes[i];
                attributes[attrNode.name] = attrNode.value;
            }

            // 속성과 값 포함
            results.push({
                attributes,
                value: element.textContent?.trim() || "",
            });
        }

        return results;
    } catch (error) {
        console.error('XML 태그 값 추출 중 에러 발생:', error);
        return [];
    }
}

// 광고 트래킹 로그 보내는 함수
export function sendTracking  (url) {
    if (!window.AdParameter || !window.AdParameter.sendTracking) {
        console.warn('광고 함수 없음');
        return;
    }
    window.AdParameter.sendTracking(url);
};

// 퍼센트 값과 이벤트 이름 매핑
export const PERCENT_MAP = {
    firstQuartile: 25,
    midpoint: 50,
    thirdQuartile: 75,
    complete: 100,
};


/**
 * 이벤트 등록 함수
 * 
 * 주어진 이벤트 배열에서 특정 조건(offset 또는 PERCENT_MAP 값)에 따라 
 * 이벤트 리스너를 등록하고, 컴포넌트 언마운트 시 이를 제거할 수 있는 함수를 반환
 *
 * @param {Array} events - 등록할 트래킹 이벤트 배열
 * @param {Function} setOnConditionReached - 조건이 충족되었을 때 실행할 리스너 등록 함수
 * @param {Function} callback - 조건이 충족되었을 때 실행할 콜백 함수
 * @returns {Function} - 등록된 모든 리스너를 제거하는 함수
 */
export const registerTrackingEvents = (events, setOnConditionReached, callback) => {
    const removeListeners = [];

    events.forEach((trackingEvent) => {
        // 이벤트의 offset 값을 가져오거나 PERCENT_MAP에서 대응되는 값 추출
        const offset = trackingEvent.attributes.offset || PERCENT_MAP[trackingEvent.attributes.event];
        if (offset !== undefined) {
            // 조건 충족 시 실행할 리스너 등록
            const removeListener = setOnConditionReached(offset, () => callback(trackingEvent, offset));
            removeListeners.push(removeListener); // 리스너 제거를 위한 함수 저장
        }
    });

    // 등록된 모든 리스너를 제거하는 함수 반환
    return () => {
        removeListeners.forEach((remove) => remove?.());
    };
};

/**
 * user agent 문자열에서 디바이스 모델명을 추출하는 함수
 * 
 * @param {string} userAgent - user agent 문자열
 * @returns {string} - 추출된 디바이스 모델명 or 빈 문자열
 */
export const extractModel = (userAgent) => {
    // Android 디바이스 모델 추출
    const androidMatch = userAgent.match(/;\s*([^\s;]+)\s*Build/);
    if (androidMatch) {
        const model = androidMatch[1];
        if (model.length > 1 && /^[A-Za-z0-9-]+$/.test(model)) {
            // 모델명이 K 등 비정상적으로 짧거나 의미 없는 값이 아닌 경우 반환
            return model;
        }
    }

    // iOS 디바이스 확인 (iPhone, iPad, iPod 등)
    const iosMatch = userAgent.match(/\((iPhone|iPad|iPod)[^;]*;/);
    if (iosMatch) {
        return iosMatch[1]; // iOS 기기명(iPhone, iPad 등) 반환
    }

    // 기타 디바이스 확인 (갤럭시 탭 등)
    const generalMatch = userAgent.match(/;\s*([^;\)]+)\s*\)/);
    if (generalMatch) {
        const model = generalMatch[1];
        if (model.length > 1 && /^[A-Za-z0-9-]+$/.test(model)) {
            return model;
        }
    }

    // 모델명을 찾을 수 없는 경우 플랫폼 이름 반환
    if (/Android/i.test(userAgent)) {
        return 'Android'; // Android 플랫폼
    }
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
        return 'iPhone'; // iOS 플랫폼
    }

    // 기본값 반환
    return '';
}